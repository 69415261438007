import React from 'react';
// import { Link } from "gatsby"

import Header from '../components/header';
import SEO from '../components/seo';
import Footer from '../components/footer';

const SecondPage = () => (
    <div className='mainbody'>
        <SEO title='About' />
        <Header pageStyle = 'headerScroll' />
        <div className='site-content'>
            <hr />
            <br />
            <h1>About Palakkad Libre software Users Society</h1>
            <div className="about-text-section post-content"> Palakkad Libre software Users Society [ Formerly Palakkad GNU/Linux User Society ] supports FOSS(Free and Open Source Software) related activities in Palakkad. <br />
                PLUS is dedicated to promoting computer users' rights to use, study, copy, modify, and redistribute computer programs. PLUS	promotes the development and use of free software, particularly the GNU operating system, used widely in its GNU/Linux variant. <br /><br />
                We support the Free Software Foundation's mission to preserve, protect and promote the freedom to use, study, copy, modify, and redistribute computer software, and to defend the rights of Free Software users. We support the freedoms of speech, press, and association on the Internet, the right to use encryption software for private communication, and theright to write software unimpeded by private monopolies. You can also read more about these issues by subscribing to the independent Free Software Magazine.
            <br />
                <br />
                PLUS members used to meet on every second saturday at Kerala School Teachers Association Office, Koppam, Palakkad.
            <br />
                <br />
                PLUS have a Matrix Chatroom and loomio.org discussion group to support it's members and help them to achieve the goals. One can join both groups.</div>
            <hr />
            <h2>Why we're not a GNU/Linux User Group</h2>

            Many people adopt and use GNU/Linux purely on the basis of technical and cost criteria, without any appreciation for the underlying message of software freedom. We feel that describing ourselves as a Free Software User Group rather than a GNU/Linux User Group will contribute to a better appreciation of that underlying message.
            <br />
            <br />
            Also, the free software cause is much bigger than any one operating system. Besides the fact that GNU/Linux is not the only free operating system today (our group welcomes users of other free operating systems too), one must also appreciate the fact that in the fast changing world of computer science and operating systems technology, no operating system or kernel is eternal. The message of software freedom, however, is.
            <hr />
            <h3>RMS's Message</h3>
            <br />
            Hello, Libre users of Palakkad! It is exciting to address a group of people who understand what GNU is really all about. Most operating systems were developed for commercial motives or technical motives, but GNU was developed for ethical ideals: freedom and community.

            All user groups invite, teach and help people to use the GNU/Linux system, and I'm sure you are going to do that. But your commitment to freedom means you can also do jobs that get to the root of the matter, campaigning for software freedom in your schools, in your civic institutions, and in all areas of life. Thank you for helping our community, and best wishes to your work.
                <br />
            Link to original Message
                <br />
            <br />
            <hr />
            <br />
            <h3>Participation</h3>

            <em>"Those who profess to favour freedom, yet depreciate agitation, are men who want crops without plowing up the ground. They want rain without thunder and lighting. They want the ocean without the awful roar of it's many water'. This struggle may be a moral one; or it may be physical one; or it may both moral and physical; but it must be a stuggle. power concedes nothing without a demand. It never did and it never will."</em>
            <br></br>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Frederick Douglas, American Abolitionist
<br></br>
            <hr />
            <br />
            <h3>Epilogue</h3>
            <br />

            Regards to all friends who contributed. We Encourage People to be associated with us, to be part of us and spread the freedom, spread the word.
<br />
            Copyright © 2006 Palakkad Libre software Users Society
<br />
            Verbatim copying and distribution of this entire article are permitted worldwide, without royalty, in any medium, provided this notice, and the copyright notice, are preserved.
<br />
            Logo designed by Derric Mathew [ derric [dot] mathew [at] gmail [dot] com ] using GIMP
<br />
            Author: Riyaz Usman [ riyazusman [at] gmail [dot] com ]
<br />
        </div>
        <Footer />
    </div>
);

export default SecondPage;
